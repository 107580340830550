@import url('https://fonts.googleapis.com/css2?family=Bonheur+Royale&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities{
    .card
    {
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
    }
}

svg path {
    stroke-width: 1;
}
